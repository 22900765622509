
import { Watch, Mixins, Component, Vue, Ref, InjectReactive, PropSync, Emit } from "vue-property-decorator";

import UploadImg from "@/components/uploadImg.vue";

import { Form } from "element-ui";
import { IHomeInstance } from "@/types/global";
import { healthExamineSave, healthExamScoreRule } from "@/api/healthEvaluation";

import { cloneDeep } from "lodash";

@Component({
    components: { UploadImg }
})
export default class FillFormScore extends Vue {
    @InjectReactive() HomeInstance !: IHomeInstance;
    @Ref("baseFormRef") baseFormRef !: Form;
    @PropSync("stepActive") syncStepActive !: number;
    @PropSync("examineId") syncExamineId !: number;
    @PropSync("step3Done") syncStep3Done !: boolean;
    @PropSync("baseMaxScore") syncBaseMaxScore :number;

    scoreTypeOptions = [
        { label: "<", value: 0 },
        { label: ">", value: 1 },
    ]

    form = {
        id: "",
        scoreRatio: 1,
        scoreInteger: 0,
        maxScore: 0,
        scoreConfig: {
            health: {
                type: null,
                score: undefined,
            },
            subHealth: {
                min: "",
                max: "",
            },
            risk: {
                type: null,
                score: undefined,
            },
        },
        status: 0,
        remark: "",
    };

    rules = {
        scoreRatio: [{ required: true, message: "请输入得分乘系数" }],
        scoreInteger: [{ required: true, message: "请选择得分取整数" }],
        status: [{ required: true, message: "请选择状态" }],
        maxScore: [{ validator: this.maxScoreValidator, message: "请填写配置" }]
    }

    saveLoading = false;

    mounted() {
        this.initScoreRule();
        this.baseMaxScoreComputed();
    }

    scoreRatioChange() {
        this.form.scoreConfig.health.score = undefined;
        this.form.scoreConfig.risk.score = undefined;
    }


    @Watch("form.scoreRatio", { immediate: true })
    @Watch("form.scoreInteger", { immediate: true })
    baseMaxScoreComputed() {
        const { scoreRatio, scoreInteger } = this.form;
        if (!scoreRatio) return this.syncBaseMaxScore?.toFixed(2);
        let score = (this.syncBaseMaxScore * scoreRatio)?.toFixed(2);
        if (scoreInteger) score = parseInt(score, 10).toString();
        this.form.maxScore = Number(score);
    }

    initScoreRule() {
        if (!this.syncExamineId) return;
        healthExamScoreRule(this.syncExamineId).then((res) => {
            const { id, scoreRatio, scoreInteger, maxScore, status, remark, basicTotal,
                healthLevelRule, healthLevelScore,
                subHealthScore1, subHealthScore2,
                riskLevelRule, riskLevelScore,
            } = res.data;

            this.syncBaseMaxScore = basicTotal || 0;

            if (id) {
                Object.assign(this.form, {
                    id,
                    scoreRatio, scoreInteger, maxScore, status, remark,
                    scoreConfig: {
                        health: { type: healthLevelRule, score: healthLevelScore },
                        subHealth: { min: subHealthScore1, max: subHealthScore2 },
                        risk: { type: riskLevelRule, score: riskLevelScore }
                    },
                })
            }
            this.$nextTick(() => {
                this.baseMaxScoreComputed();
            })
        })
    }

    maxScoreValidator(rule, value, callback) {
        const { health, risk } = this.form.scoreConfig;
        if (health.type?.toString() && risk.type?.toString()
           && health.score?.toString() && risk.score?.toString()) {
            return callback();
        }
        return callback(new Error());
    }

    get healthMaxAndMin() {
        const { health, risk } = this.form.scoreConfig;
        let min = 0, max = 0;
        if (health.type == 0) {
            if (risk.score) {
                max = risk.score;
            } else {
                max = this.form.maxScore;
            }
        }
        if (health.type == 1) {
            if (risk.score) {
                min = risk.score;
            }
            max = this.form.maxScore;
        }
        return { max, min }
    }

    get riskMaxAndMin() {
        const { health, risk } = this.form.scoreConfig;
        let min = 0, max = 0;
        if (risk.type == 0) {
            if (health.score) {
                max = health.score;
            } else {
                max = this.form.maxScore;
            }
        }
        if (risk.type == 1) {
            if (health.score) {
                min = health.score;
            } else {
                min = 0;
            }
            max = this.form.maxScore;
        }
        return { max, min }
    }

    healthTypeChange($event) {
        this.form.scoreConfig.risk.type = Number(!$event);
        this.form.scoreConfig.risk.score = undefined;
    }

    riskTypeChange($event) {
        this.form.scoreConfig.health.type = Number(!$event);
        this.form.scoreConfig.health.score = undefined;
    }

    @Watch("form.scoreConfig.health", { deep: true })
    @Watch("form.scoreConfig.risk", { deep: true })
    setSubHealth() {
        const { health, risk } = this.form.scoreConfig;
        if (health.type == 0) {
            this.form.scoreConfig.subHealth.min = health.score.toFixed(2);
            this.form.scoreConfig.subHealth.max = risk.score.toFixed(2);
        }
        if (health.type == 1) {
            this.form.scoreConfig.subHealth.min = risk.score.toFixed(2);
            this.form.scoreConfig.subHealth.max = health.score.toFixed(2);
        }
    }

    cancel() {
        this.HomeInstance.tabRemove("healthEvaluationFill");
    }

    preStep() {
        this.syncStepActive = 1;
    }

    @Emit("closeTab")
    closeTab() {}

    save() {
        this.baseFormRef.validate().then(() => {
            const { id, scoreRatio, scoreInteger, maxScore, scoreConfig, status, remark } = this.form;
            const params = {
                healthScoreRule: {
                    id,
                    healthId: this.syncExamineId,
                    scoreRatio, scoreInteger, maxScore, status, remark,
                    healthLevelRule: scoreConfig.health.type, healthLevelScore: scoreConfig.health.score,
                    subHealthScore1: scoreConfig.subHealth.min, subHealthScore2: scoreConfig.subHealth.max,
                    riskLevelRule: scoreConfig.risk.type, riskLevelScore: scoreConfig.risk.score,
                },
                step: 3,
            }

            this.saveLoading = true;
            healthExamineSave(params).then((res) => {
                this.syncStepActive = 2;
                const { data } = res;
                this.syncExamineId = data;
                this.syncStep3Done = true;
                this.$message.success("操作成功")
                this.closeTab();
            }).finally(() => {
                this.saveLoading = false;
            })
        })
    }
}
