
import { Watch, Emit, Component, Vue, Ref, InjectReactive, PropSync } from "vue-property-decorator";
import { getKey } from "@/utils/uuid";

import UploadImg from "@/components/uploadImg.vue";

import { Form } from "element-ui";

import { healthExamineSave, healthQuestionSummaryList, healthQuestionAnswerList } from "@/api/healthEvaluation";

import { IHomeInstance } from "@/types/global";

const getBaseQuestion = () => {
    return { _id: getKey(), title: "", answer: [
        { title: "", score: undefined },
        { title: "", score: undefined },
        { title: "", score: undefined },
        { title: "", score: undefined },
    ] };
}

@Component({
    components: { UploadImg }
})
export default class FillFormQuestion extends Vue {
    @InjectReactive() HomeInstance !: IHomeInstance;
    @PropSync("step2Done") syncStep2Done !: boolean;
    @Ref("baseFormRef") baseFormRef !: Form;
    @PropSync("stepActive") syncStepActive !: number;
    @PropSync("examineId") syncExamineId !: number;
    @PropSync("baseMaxScore") syncBaseMaxScore;

    questionList = [];

    form = {
        questionList: [],
    };

    saveLoading = false;

    mounted() {
        this.initDefaultQuestionList();
        this.initQuestionList();
    }

    initQuestionList() {
        if (!this.syncExamineId || !this.syncStep2Done) return;
        const promise1 = healthQuestionSummaryList(this.syncExamineId);
        const promise2 = healthQuestionAnswerList(this.syncExamineId);
        Promise.all([promise1, promise2]).then(([res1, res2]) => {
            const answerList = res2.data;
            const questionList = res1.data.map((que) => {
                const answer = answerList.filter((ans) => ans.questionId == que.id)?.map((ans) => ({ title: ans.answer, score: ans.score }))
                return {
                    title: que.question,
                    answer,
                }
            })
            this.form.questionList = questionList;
        })
    }

    validatorAnswer(rule, value, callback) {
        const [key1, index1, key2, index2] = rule.field.split(".");
        const { title, score } = this.form.questionList[index1].answer[index2];
        if (title && score?.toString()) {
            return callback();
        }
        return callback(new Error());
    }

    initDefaultQuestionList() {
        this.form.questionList = new Array(5).fill({}).map((el) => (getBaseQuestion()))
    }

    delQuestion($event) {
        if (this.form.questionList?.length <= 1) return;
        this.form.questionList.splice($event, 1);
    }

    addQuestion($event) {
        if (this.form.questionList?.length >= 200) return;
        this.form.questionList.splice($event+1, 0, getBaseQuestion());
    }

    delAnswer(index, index2) {
        const answer = this.form.questionList[index].answer;
        if (answer?.length <= 1) return;
        answer.splice(index2, 1);
    }

    addAnswer(index, index2) {
        const answer = this.form.questionList[index].answer;
        answer.splice(index2+1, 0, { title: "", score: undefined });
    }

    cancel() {
        this.HomeInstance.tabRemove("healthEvaluationFill");
    }

    preStep() {
        this.syncStepActive = 0;
    }

    @Emit("closeTab")
    closeTab() {}

    save() {
        this.baseFormRef.validate().then(() => {
            const healthExamQAndADTOS = [];
            this.form.questionList.forEach((que, queIndex) => {
                healthExamQAndADTOS.push({
                    answers: que.answer.map((ans, ansIndex) => ({ answer: ans.title, score: ans.score, answerSeq: ansIndex })),
                    healthQuestionSummary: { healthId: this.syncExamineId, question: que.title,
                        sort: queIndex
                    }
                })
            })
            const baseMaxScore = this.form.questionList.reduce((acc,cur) => {
                return acc += cur.answer.sort((a,b) => b.score - a.score)[0]?.score;
            }, 0)
            const params = {
                healthExamQAndADTOS,
                healthExamineProfile: {
                    basicTotal: baseMaxScore,
                },
                step: 2,
            }
            this.saveLoading = true;
            healthExamineSave(params).then((res) => {
                const { data } = res;
                this.syncExamineId = data;
                this.syncBaseMaxScore = baseMaxScore;
                this.syncStepActive = 2;
                this.syncStep2Done = true;
            }).finally(() => {
                this.saveLoading = false;
            })
        }).catch(() => {
            const dom = document.querySelector(".is-error");
            if (!dom) return;
            dom.scrollIntoView({behavior: 'smooth'})
        })
    }
}
