
import { Watch, Mixins, Component, Vue, InjectReactive } from "vue-property-decorator";

import FillFormBase from "./components/formBase.vue";
import FillFormQuestion from "./components/formQuestion.vue";
import FillFormScore from "./components/formScore.vue";

import { IHomeInstance } from "@/types/global";

@Component({
    components: { FillFormBase, FillFormQuestion, FillFormScore }
})
export default class HealthEvaluationFill extends Vue {
    @InjectReactive() HomeInstance !: IHomeInstance;

    stepActive = 0;
    baseMaxScore = 0;
    examineId = this.$route.query.id || "";
    // @ProvideReactive()
    step1Done = false;
    // @ProvideReactive()
    step2Done = false;
    // @ProvideReactive()
    step3Done = false;

    mounted() {
        this.setActiveStep();
        this.setStepDone();
    }

    get activeSteps() {
        if (this.step3Done) return 3;
        if (this.step2Done) return 2;
        if (this.step1Done) return 1;
        return 1;
    }

    setActiveStep() {
        const step = this.$route.query.step as unknown as number;
        if (!step) {
            this.stepActive = 0;
        } else {
            this.stepActive = step;
            if (step == 3) this.stepActive = 2
        }
    }

    setStepDone() {
        const step = this.$route.query.step as unknown as number;
        if (step == 1) {
            this.step1Done = true;
        }
        if (step == 2) {
            this.step1Done = true;  
            this.step2Done = true;
        }
        if (step >= 3) {
            this.step1Done = true;
            this.step2Done = true;
            this.step3Done = true;
        }
    }

    getStepStatus(step: number): string {
        return step <= this.stepActive ? "finish" : "";
    }

    cancel() {
        this.HomeInstance.tabRemove("healthEvaluationFill");
    }
}

