import { service } from '@/ajax/request'

export const healthExamineList = (params) => {
    return service({
        url: `/healthexamine/healthExamineProfile/list`,
        method: 'GET',
        params,
        type: "form"
    })
}

export const healthExamineSave = (params) => {
    return service({
        url: `/healthexamine/healthExamineProfile/save?step=${params.step}`,
        method: 'POST',
        params,
    })
}

export const healthExamSuggestList = (params) => {
    return service({
        url: `/sys/sysHealthExamSuggest/list`,
        method: 'POST',
        params,
    })
}

export const healthExamSuggestSave = (params) => {
    return service({
        url: `/sys/sysHealthExamSuggest/save`,
        method: 'POST',
        params
    })
}

export const healthExamSuggestNames = () => {
    return service({
        url: `/sys/sysHealthExamSuggest/getNames`,
        method: 'GET',
        type: "form"
    })
}

export const healthExamScoreRule = (healthId) => {
    return service({
        url: `/healthexamine/healthScoreRule/list?healthId=${healthId}`,
        method: 'GET',
        type: "form"
    })
}

export const healthQuestionSummaryList = (healthId) => {
    return service({
        url: `/healthexamine/healthQuestionSummary/list?healthId=${healthId}`,
        method: 'GET',
        type: "form"
    })
}

export const healthQuestionAnswerList = (healthId) => {
    return service({
        url: `/healthexamine/healthQuestionAnswer/list?healthId=${healthId}`,
        method: 'GET',
        type: "form"
    })
}

export const healthExamineProfile = (healthId) => {
    return service({
        url: `/healthexamine/healthExamineProfile/getInfoById?healthId=${healthId}`,
        method: 'GET',
        type: "form"
    })
}