
import { Watch, Emit, Component, Vue, Ref, InjectReactive, PropSync } from "vue-property-decorator";

import UploadImg from "@/components/uploadImg.vue";

import { Form } from "element-ui";

import { healthExamineSave, healthExamineProfile } from "@/api/healthEvaluation";

@Component({
    components: { UploadImg }
})
export default class FillFormBase extends Vue {
    @Ref("baseFormRef") baseFormRef !: Form;
    @PropSync("stepActive") syncStepActive !: number;
    @PropSync("examineId") syncExamineId !: number;
    @PropSync("step1Done") syncStep1Done !: boolean;

    stepActive = 0;
    form = {
        examName: "",
        showName: "",
        questionTime: undefined,
        sort: undefined,
        dependency: "",
        files: [],
    };

    saveLoading = false;

    rules = {
        examName: [{ required: true, message: "请输入测评名称" }],
        showName: [{ required: true, message: "请输入外显名称" }],
        questionTime: [{ required: true, message: "请输入做题时间" }, { type: "number" }],
        files: [{ required: true, message: "请上传封面" }, { type: "array" }],
    }

    mounted() {
        this.initHealthExamineProfile();
    }

    initHealthExamineProfile() {
        if (!this.syncExamineId || !this.syncStep1Done) return;
        healthExamineProfile(this.syncExamineId).then((res) => {
            const { examName, showName, questionTime, sort, dependency, icon } = res.data;
            Object.assign(this.form, { examName, showName, questionTime, sort, dependency, files: [{ url: icon }], icon })
            
        })
    }

    @Emit("closeTab")
    closeTab() {}


    save() {
        this.baseFormRef.validate().then(() => {
            const params = { ...this.form, icon: this.form.files[0]?.url, id: this.syncExamineId }
            this.saveLoading = true;
            healthExamineSave({healthExamineProfile: params,  step: 1}).then((res) => {
                this.syncStepActive = 1;
                const { data } = res;
                this.syncExamineId = data;
                this.syncStep1Done = true;
            }).finally(() => {
                this.saveLoading = false;
            })
        })
    }
}
